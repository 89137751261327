import Unit from "../../interfaces/entities/Unit";
import { IActions } from "../actions/Interfaces";
import { ActionTypes } from "../actionTypes";
import { Store, initialStore } from "../storeStates/StoreStates"
import { v4 as RandomUUID } from "uuid";
import Configuration from "../../interfaces/entities/Configuration";
import ImpactFactor from "../../interfaces/entities/ImpactFactor";
import Actions from "../actions/Actions";

const RootReducer = (state = initialStore, action: IActions) => {
    let tempUnits: Unit[];
    let tempConfigs: Configuration[];
    let tempImpactFactors: ImpactFactor[];
    let newState: Store;
    switch (action.type) {

        case ActionTypes.SET_DATALOADED:
            newState = {
                ...state,
                dataLoaded: action.payload
            };
            return newState;

        /**
         * Analysis
         */
        case ActionTypes.SET_TEXT_ANALYSIS:
            newState = {
                ...state,
                textAnalysisResult: action.payload
            };
            return newState;

        /**
         * Filter
         */
        case ActionTypes.SET_DASHBOARD_FILTER:
            newState = {
                ...state,
                dashboardCheckboxFilter: action.payload
            };
            return newState;
            
        /**
         * Language
         */
        case ActionTypes.SET_AVAILABLE_LANGUAGES:
            newState = {
                ...state,
                availableLanguages: action.payload
            };
            return newState;

        case ActionTypes.SET_CURRENT_LANGUAGE:
            newState = {
                ...state,
                translation: action.payload
            };
            return newState;

        /**
         * Organization
         */
        case ActionTypes.SET_AVAILABLE_ORGANIZATIONS:
            newState = {
                ...state,
                availableOrganizations: action.payload
            };
            return newState;
        case ActionTypes.SET_ORGANIZATION:
            newState = {
                ...state,
                organization: action.payload
            };
            return newState;

        case ActionTypes.SET_SELECTED_ORGANIZATION_ID:
            newState = {
                ...state,
                selectedOrganizationId: action.payload
            };
            return newState;

        /**
         * Units
         */
        case ActionTypes.SET_SELECTED_UNITS:
            newState = {
                ...state,
                selectedUnits: action.payload
            };
            return newState;

        case ActionTypes.SET_UNITS:
            newState = {
                ...state,
                units: action.payload
            };
            return newState;

        case ActionTypes.ADD_UNIT:
            // action.payload.unitId = RandomUUID();
            newState = {
                ...state,
                units: [...state.units, action.payload],
            };
            return newState;

        case ActionTypes.UPDATE_UNITS:
            tempUnits = [...state.units];
            action.payload.forEach(element => {
                const index = state.units.findIndex(unit => unit.unitId === element.unitId)
                tempUnits[index] = element;
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        case ActionTypes.DELETE_UNITS:
            tempUnits = [...state.units];

            action.payload.forEach(element => {
                // tempUnits = tempUnits.filter(unit => unit.unitId !== element.unitId);
                element.active = 0;
                element.users.forEach(user => {
                    user.active = 0;
                })

                const index = state.units.findIndex(unit => unit.unitId === element.unitId)
                tempUnits[index] = element;
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        /**
         * Users
         */
        case ActionTypes.SET_SELECTED_USERS:
            newState = {
                ...state,
                selectedUsers: action.payload
            };
            return newState;

        case ActionTypes.SET_USERS:
            newState = {
                ...state,
                users: action.payload
            };
            return newState;

        case ActionTypes.ADD_USER:
            action.payload.userId = RandomUUID();
            tempUnits = [...state.units];
            tempUnits.forEach(unit => {
                if (unit.unitId === action.payload.unit.id) {
                    unit.users.push(action.payload);
                    unit.size += 1;
                }
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        case ActionTypes.UPDATE_USERS:
            tempUnits = [...state.units];
            action.payload.forEach(element => {
                tempUnits.forEach(unit => {
                    if (unit.users.find(user => user.userId === element.userId)) {
                        let index = unit.users.findIndex(user => user.userId === element.userId);
                        unit.users[index] = element;
                    }
                });
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        case ActionTypes.DELETE_USERS:
            tempUnits = [...state.units];
            action.payload.forEach(element => {
                tempUnits.forEach(unit => {
                    // unit.users = unit.users.filter(user => user.userId !== element.userId);
                    // unit.size -= action.payload.length;
                    element.active = 0;
                    if (unit.users.find(user => user.userId === element.userId)) {
                        let index = unit.users.findIndex(user => user.userId === element.userId);
                        unit.users[index] = element;
                    }
                });
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        /**
         * Assessments and AssessmentEvents
         */
        case ActionTypes.ADD_UNIT_ASSESSMENT_EVENTS:
            tempUnits = [...state.units];
            tempUnits.forEach(unit => {
                unit.unitAssessmentEvents.push.apply(unit.unitAssessmentEvents, action.payload);
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        case ActionTypes.DELETE_UNIT_ASSESSMENT_EVENTS:
            tempUnits = [...state.units];
            tempUnits.forEach(unit => {
                action.payload.forEach(assessmenEventToDelete => {
                    unit.unitAssessmentEvents = unit.unitAssessmentEvents.filter(assessmenEvent => assessmenEvent.assessmentEventId !== assessmenEventToDelete.assessmentEventId);
                });
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        case ActionTypes.ADD_USER_ASSESSMENT_EVENTS:
            tempUnits = [...state.units];
            tempUnits.forEach(unit => {
                unit.users.forEach(user => {
                    user.userAssessmentEvents.push.apply(user.userAssessmentEvents, action.payload);
                })
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        case ActionTypes.DELETE_USER_ASSESSMENT_EVENTS:
            tempUnits = [...state.units];
            tempUnits.forEach(unit => {
                unit.users.forEach(user => {
                    action.payload.forEach(aeToDelete => {
                        user.userAssessmentEvents = user.userAssessmentEvents.filter(ae => ae.assessmentEventId !== aeToDelete.assessmentEventId);
                    });
                })
            });
            newState = {
                ...state,
                units: tempUnits
            };
            return newState;

        /**
        * Impact Factors
        */
        case ActionTypes.SET_IMPACT_FACTORS:
            newState = {
                ...state,
                impactFactors: action.payload
            };
            return newState;

        case ActionTypes.ADD_IMPACT_FACTORS:
            action.payload.forEach((element) => element.impactFactorId = RandomUUID()); // Sets a random UUID as id
            newState = {
                ...state,
                impactFactors: [...state.impactFactors].concat(action.payload)
            };
            return newState;

        case ActionTypes.DELETE_IMPACT_FACTORS:
            tempImpactFactors = [...state.impactFactors];
            action.payload.forEach(element => {
                tempImpactFactors = tempImpactFactors.filter(x => x.impactFactorId !== element.impactFactorId)
            });
            newState = {
                ...state,
                impactFactors: tempImpactFactors
            };
            return newState;

        /**
         * Configurations
         */
        case ActionTypes.SET_CONFIGURATIONS:
            newState = {
                ...state,
                configurations: action.payload
            };
            return newState;

        case ActionTypes.ADD_CONFIGURATIONS:
            action.payload.forEach((element) => element.configurationId = RandomUUID()); // Sets a random UUID as id
            newState = {
                ...state,
                configurations: [...state.configurations].concat(action.payload)
            };
            return newState;

        case ActionTypes.UPDATE_CONFIGURATIONS:
            tempConfigs = [...state.configurations];
            action.payload.forEach(element => {
                const index = state.configurations.findIndex(config => config.configurationId === element.configurationId)
                tempConfigs[index] = element;
            });
            newState = {
                ...state,
                configurations: tempConfigs
            };
            return newState;

        case ActionTypes.DELETE_CONFIGURATIONS:
            tempConfigs = [...state.configurations];
            action.payload.forEach(element => {
                tempConfigs = tempConfigs.filter(config => config.configurationId !== element.configurationId)
            });
            newState = {
                ...state,
                configurations: tempConfigs
            };
            return newState;

        /**
         * Views and forms
         */
        case ActionTypes.SET_VIEW:
            newState = {
                ...state,
                currentView: action.payload
            };
            return newState;

        case ActionTypes.SET_SHOW_CONFIRMATION_DIALOG:
            newState = {
                ...state,
                showConfirmationDialog: action.payload
            };
            return newState;

        case ActionTypes.SET_SHOW_FORM:
            newState = {
                ...state,
                showForm: action.payload
            };
            return newState;

        case ActionTypes.SET_SHOW_SNACKBAR:
            newState = {
                ...state,
                showSnackBar: action.payload
            };
            return newState;

        case ActionTypes.SET_KEYCLOAK:
            newState = {
                ...state,
                keycloak: action.payload
            };
            return newState;
        default:
            return state;
    }
}

export default RootReducer;