import Keycloak from "keycloak-js";
import Configuration from "../../interfaces/entities/Configuration";
import ImpactFactor from "../../interfaces/entities/ImpactFactor";
import { LanguageModel } from "../../interfaces/entities/LanguageModel";
import Organization from "../../interfaces/entities/Organization";
import Unit from "../../interfaces/entities/Unit";
import User from "../../interfaces/entities/User";
import { FormTypes, Views } from "../../interfaces/ui/Enums";
import { Form, ISnackbarProps } from "../../interfaces/ui/Interfaces";
import defaultEntities from "../../utils/EntityUtils";
import translation from "../../translations/swedish.json";
import { DashboardCheckboxFilter } from "../../interfaces/entities/DashboardCheckboxFilter";
import TextAnalysisResult from "../../interfaces/entities/analysis/TextAnalysisResult";

export interface Store {
    organization: Organization;
    selectedOrganizationId: string;
    selectedUnits: Unit[];
    units: Unit[];
    selectedUsers: User[];
    users: User[];
    // addedUnit: Unit;

    // unitAssessmentEvents: UnitAssessmentEvent[];
    impactFactors: ImpactFactor[];
    configurations: Configuration[];

    currentView: Views;

    showForm: Form;
    showConfirmationDialog: boolean;
    showSnackBar: ISnackbarProps;

    dataLoaded: boolean;
    availableOrganizations: Organization[];

    keycloak: any;

    availableLanguages: LanguageModel[] | null;
    translation: any;

    dashboardCheckboxFilter: DashboardCheckboxFilter;
    textAnalysisResult: TextAnalysisResult[];
}

export const initialStore: Store = {
    organization: defaultEntities.defaultOrganization,
    selectedOrganizationId: "",
    selectedUnits: [] as Unit[],
    units: [],
    selectedUsers: [] as User[],
    users: [],
    // addedUnit: {} as Unit,

    impactFactors: [],
    configurations: [defaultEntities.defaultConfiguration],

    currentView: Views.DASHBOARD,

    showForm: { show: false, type: FormTypes.NEW },
    showConfirmationDialog: false,
    showSnackBar: defaultEntities.defaultSnackbarProps,

    dataLoaded: false,
    availableOrganizations: [],

    keycloak: null,

    availableLanguages: null,
    translation: {"language": translation.language, "dictionary": translation.dictionary} as LanguageModel | any,

    dashboardCheckboxFilter: defaultEntities.defaultDashboardCheckboxFilter,
    textAnalysisResult: [],
}