import { Paper } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Area, Legend } from "recharts";
import Configuration from "../../interfaces/entities/Configuration";
import { ChartProps } from "../../interfaces/ui/Interfaces";
import { store } from "../../redux/storeStates/store";
import { Store } from "../../redux/storeStates/StoreStates";

const colors = [
    "#202020", "#505050", "#808080", "#202020", "#505050", "#808080", //, "#8884d8", "#82ca9d", "#8dd1e1", "#ffc658", "#83a6ed", "#a4de6c",
]

function getImpactFactorValues(props: ChartProps, activeConfig: Configuration) {
    if (!(props.units.length > 0)) return [];

    let countPerDate: { date: string, count: number }[] = []; //How many units/users that made their assessments a specific date
    let impactData: { date: string }[] | any[] = [];
    let to = moment(props.to).add(1, "day").format("YYYY-MM-DD");

    if (props.includeUnits) {
        props.units.forEach(unit => {
            if (unit.active === 1 && unit.unitAssessmentEvents && unit.unitAssessmentEvents.length > 0) {
                for (let ua of unit.unitAssessmentEvents) {
                    if (moment(moment(ua.plannedDate).format("YYYY-MM-DD")).isBetween(moment(props.from), moment(to)) && ua.status) {

                        if (ua.unitAssessment && (ua.unitAssessment.unitImpactAssessmentValues.length >= activeConfig.impactFactorConfigurations.length)) {
                            let impactDataObject: any = { date: ua.plannedDate };

                            //Set up an impactDataObject based on the active config
                            activeConfig.impactFactorConfigurations.map(val => {
                                if(props.calculationMode) {
                                    impactDataObject[val.displayName] = [0,0,0];
                                } else {
                                    impactDataObject[val.displayName] = -1;
                                }
                            })

                            if (impactData.some(i => i.date === impactDataObject.date)) {
                                let index = impactData.findIndex(i => i.date === impactDataObject.date);
                                ua.unitAssessment.unitImpactAssessmentValues.map(val => {
                                    if(props.calculationMode) {
                                        impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = impactData[index][getDisplayName(val.impactFactor.impactFactorId)];                                                
                                        impactDataObject[getDisplayName(val.impactFactor.impactFactorId)][val.value] += 1;                                                
                                    } else {
                                        impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = impactData[index][getDisplayName(val.impactFactor.impactFactorId)] + val.value;
                                    }
                                })
                                impactData[index] = impactDataObject;

                                let countIndex = countPerDate.findIndex(c => c.date === impactDataObject.date);
                                countPerDate[countIndex].count += 1;
                            } else {
                                ua.unitAssessment.unitImpactAssessmentValues.map(val => {
                                    if(props.calculationMode) {
                                        impactDataObject[getDisplayName(val.impactFactor.impactFactorId)][val.value] = 1;
                                    } else {
                                        impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = val.value;
                                    }
                                })
                                impactData.push(impactDataObject);
                                countPerDate.push({ date: ua.plannedDate, count: 1 });
                            }
                        }
                        else {
                            break;
                        }
                    }

                }
            }
        })
    } else if (props.includeUsers) {
        props.units.forEach(unit => {
            if (unit.users && unit.users.length > 0 && unit.active === 1) {

                unit.users.forEach(user => {
                    if (user.active === 1 && user.userAssessmentEvents && user.userAssessmentEvents.length > 0) {
                        for (let ua of user.userAssessmentEvents) {
                            if (moment(moment(ua.plannedDate).format("YYYY-MM-DD")).isBetween(moment(props.from), moment(to), null, "[]") && ua.status) {
                                // TODO fix activeConfig
                                if (ua.userAssessment && ua.userAssessment.userImpactAssessmentValues.length >= activeConfig.impactFactorConfigurations.length) {
                                    let impactDataObject: any = { date: ua.plannedDate };

                                    //Set up an impactDataObject based on the active config
                                    // TODO fix activeConfig
                                    activeConfig.impactFactorConfigurations.map(val => {
                                        if(props.calculationMode) {
                                            impactDataObject[val.displayName] = [0,0,0];
                                        } else {
                                            impactDataObject[val.displayName] = -1;
                                        }
                                    })

                                    if (impactData.some(i => i.date === impactDataObject.date)) {
                                        let index = impactData.findIndex(i => i.date === impactDataObject.date);
                                        ua.userAssessment.userImpactAssessmentValues.map(val => {
                                            if(props.calculationMode) {
                                                impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = impactData[index][getDisplayName(val.impactFactor.impactFactorId)];                                                
                                                impactDataObject[getDisplayName(val.impactFactor.impactFactorId)][val.value] += 1;                                                
                                            } else {
                                                impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = impactData[index][getDisplayName(val.impactFactor.impactFactorId)] + val.value;
                                            }
                                        })
                                        impactData[index] = impactDataObject;

                                        let countIndex = countPerDate.findIndex(c => c.date === impactDataObject.date);
                                        countPerDate[countIndex].count += 1;
                                    } else {
                                        ua.userAssessment.userImpactAssessmentValues.map(val => {
                                            if(props.calculationMode) {
                                                // impactDataObject[getDisplayName(val.impactFactor.impactFactorId)][val.value] = 1;
                                                impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = [0,0,0];
                                                impactDataObject[getDisplayName(val.impactFactor.impactFactorId)][val.value] = 1;
                                            } else {
                                                impactDataObject[getDisplayName(val.impactFactor.impactFactorId)] = val.value;
                                            }
                                        })
                                        impactData.push(impactDataObject);
                                        countPerDate.push({ date: ua.plannedDate, count: 1 });
                                    }
                                }
                                else {
                                    break;
                                }
                            }
                        }
                    }
                    // count += 1;
                })
            }
        })
    }

    if (impactData.length === 0) return [];

    impactData = impactData.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    countPerDate = countPerDate.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    impactData.forEach(obj => {
        let date = obj.date;
        let count: number = countPerDate.find(c => c.date === date)?.count || 1;
        for (let prop in obj) {
            if(props.calculationMode) {
                if(prop === "date") {
                    obj[prop] = moment(obj.date).format("YYYY-MM-DD");
                } else {
                    let max = Math.max(...obj[prop]);
                    obj[prop] = obj[prop].indexOf(max);    
                }
            } else {
                obj[prop] = prop === "date" ? moment(obj.date).format("YYYY-MM-DD") : (obj[prop] / count).toFixed(2);
            }
        }
    })

    return impactData
}

/**
 * Based on impactFactorId, returns displayName from active config
 */
// TODO select configuration based on settings (unit or individual)
function getDisplayName(impactFactorId: string) {
    let configurations = store.getState().configurations;
    if (!(configurations.length > 0))
        return "";

    let activeConfig = configurations.filter(config => config.active)[0]
    let displayName = "?";
    activeConfig.impactFactorConfigurations.forEach(ifc => {
        if (ifc.impactFactor.impactFactorId === impactFactorId)
            displayName = ifc.displayName;
    });

    return displayName;
}

/**
 * @returns Field count of an object in an array
 */
function getMaxFieldCount(objects: any[]) {
    return Math.max(...objects.map(o => Object.keys(o).length)) - 1;
}

function getAreas(props: ChartProps) {
    let colorIndex = 0;
    let configurations = store.getState().configurations;

    if (!(configurations.length > 0)) return "";

    let activeConfig = configurations.filter(config => config.active)[0]

    return activeConfig.impactFactorConfigurations.map(value => (
        <Area type="monotone" dataKey={value.displayName} stackId={1} stroke={colors[colorIndex = colorIndex++]} fill={colors[colorIndex++]} />
    ));
}

export function ImpactFactorStackedAreaChart(props: ChartProps) {
    const configurations = useSelector((state: Store) => state.configurations); //Updates component when state.configurations changes
    return (
        <Paper elevation={3}>
            <ResponsiveContainer width="100%" minHeight={200}>
                <AreaChart
                    // width={500}
                    height={200}
                    data={getImpactFactorValues(props, configurations.filter(c => c.active === 1)[0])}
                    margin={{
                        top: 20,
                        right: 50,
                        // left: 0,
                        bottom: 10,
                    }}
                    
                >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis
                        // domain={[0, getMaxFieldCount(getImpactFactorValues(props, configurations.filter(c => c.active === 1)[0])) * 2]}
                        domain={[0, 2]}
                    />
                    <Tooltip />
                    <Legend />

                    {getAreas(props)}

                </AreaChart>
            </ResponsiveContainer>
        </Paper>
    );
}