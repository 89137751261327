import { showSnackbar } from "../../components/common/SnackBar";
import Organization from "../../interfaces/entities/Organization";
import TextAnalysisResult from "../../interfaces/entities/analysis/TextAnalysisResult";
import { AlertSeverity } from "../../interfaces/ui/Enums";
import { store } from "../../redux/storeStates/store";
import { api, getHeaders } from "./DataRepository";

export function getAnalysisByOrganizationId(id: string): Promise<TextAnalysisResult[]> {
    return new Promise((resolve, reject) => {
        api.get(`/analysis/sentiment/${id}`, getHeaders())
            .then((res) => {
                resolve(res.data as TextAnalysisResult[]);
            }).catch(err => {
                console.log(err);
                showSnackbar(AlertSeverity.ERROR, store.getState().translation.dictionary["getOrganizationFail"]);
            })
    })
}

const AnalysisRepository = {
    getById: getAnalysisByOrganizationId,
    // add: addOrganization,
    // getAll: getAllOrganizations,
}

export default AnalysisRepository;