import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { ChartProps } from '../../interfaces/ui/Interfaces';
import { store } from '../../redux/storeStates/store';
import Unit from '../../interfaces/entities/Unit';
import { Store } from "../../redux/storeStates/StoreStates";
import { useSelector } from 'react-redux';
import Sentiment from '../../interfaces/entities/Sentiment';

interface BarChartProps {
    units: Unit[];
    from: string;
    to: string;
    includeUnits: boolean;
    includeUsers: boolean;
    calculationMode: boolean;
    data: { name: string; value: number }[];
    height: number;
}

const BarChartPosNeg: React.FC<BarChartProps> = ({ units, from, to, includeUnits, includeUsers, calculationMode, data, height }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
//   const [data, setData] = useState<Data[]>([]);
  const translation = useSelector((state: Store) => state.translation);

  useEffect(() => {
    const handleResize = () => {
      if (svgRef.current) {
        const width = svgRef.current.parentElement?.offsetWidth || 0;
        setContainerWidth(width);
      }
    };

    handleResize(); // Set initial width
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current || containerWidth === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous content

    // Set up margins and dimensions
    const margin = { top: 40, right: 30, bottom: 50, left: 60 };
    const innerWidth = containerWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Calculate the step for labels
    const maxLabelWidth = 100; // Max width for each label
    const step = Math.ceil(data.length / (innerWidth / maxLabelWidth));
    console.log("Step: ", step);

    const title = translation.dictionary["sentimentAnalysis"];
    // Create tooltip element
    const tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('border', '1px solid #ccc')
      .style('padding', '10px')
      .style('display', 'none')
      .style('pointer-events', 'none');
      
    // Set up scales
    const x = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([0, innerWidth])
      .padding(0.1);

    const y = d3.scaleLinear()
      .domain([d3.min(data, d => d.value)!, d3.max(data, d => d.value)!])
      .nice()
      .range([innerHeight, 0]);

    // Append group element for margins
    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Add title
    svg.append('text')
      .attr('x', containerWidth / 2)
      .attr('y', margin.top / 2)
      .attr('text-anchor', 'middle')
      .style('font-family', 'Roboto, Helvetica, Arial, sans-serif')
      .style('font-size', '18px')
      .text(title);

    // Create bars
    g.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', d => x(d.name)!)
      .attr('y', d => d.value >= 0 ? y(d.value) : y(0))
      .attr('width', x.bandwidth())
      .attr('height', d => Math.abs(y(d.value) - y(0)))
      .attr('fill', d => d.value >= 0 ? 'steelblue' : 'red')
      .on('mouseover', (event, d) => {
        tooltip.style('display', 'block')
          .html(`<strong>${d.name}</strong><br />Value: ${d.value}`);
      })
      .on('mousemove', (event) => {
        tooltip.style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 28) + 'px');
      })
      .on('mouseout', () => {
        tooltip.style('display', 'none');
      });

    // Add X axis
    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x).tickValues(x.domain().filter((d, i) => i % step === 0)))
      .style("font-family", "Roboto, Helvetica, Arial, sans-serif")
      .style("font-size", "16px")
        .selectAll('text')
      .style('display', 'none');

    //   .call(d3.axisBottom(x));

    // Add Y axis
    g.append('g')
      .call(d3.axisLeft(y))
      .selectAll("text")
      .style("font-family", "Roboto, Helvetica, Arial, sans-serif")
      .style("font-size", "16px");

    // Add horizontal middle line
    g.append('line')
      .attr('x1', 0)
      .attr('x2', innerWidth)
      .attr('y1', y(0))
      .attr('y2', y(0))
      .attr('stroke', 'black');

  }, [data, height, containerWidth]);

  return <svg ref={svgRef} width="100%" height={height}></svg>;
};

export default BarChartPosNeg;
